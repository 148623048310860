import React, { 
    useEffect, 
    useState }                          from 'react';
import { ifPartnerLoggedIn }            from '../../../utils/auth';
import axios                            from 'axios';
import Cookie                           from 'js-cookie';
import PartnerNavigationMenu            from '../NavigationMenu/navigation-menu';
import ImageUploader                    from 'react-images-upload';
import TextAreaWithLabel                from '../../../components/TextAreaWithLabel';
import Button                           from '../../../components/Button';
import Metrics                          from './Metrics/Metrics';
import { DndProvider }                  from 'react-dnd';
import { HTML5Backend }                 from 'react-dnd-html5-backend';

import './preferences.scss';
import { Item } from './Metrics/Container';

const apiUrl = process.env.REACT_APP_API_URL;
const token = Cookie.get('accessPartnerTokenDB');

const PremiumPartnerPreferences = () => {
    const [partnerId, setPartnerId] = useState<number>(0);
    const [partnerName, setPartnerName] = useState<string>('');
    const [partnerLogo, setPartnerLogo] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [partnershipLevel, setPartnershipLevel] = useState<string>('');
    const [partnerDescription, setPartnerDescription] = useState<string>('');
    const [cards, setCards] = useState<Item[]>([]);

    useEffect(() => {
        (async () => {
            await ifPartnerLoggedIn();
            await getPartnerAdmins();
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDrop = (files: File[], partnerId: number) => {
        updateProfilePhoto(files, partnerId);
    };

    const updateProfilePhoto = async (files: File[], partnerId: number) => {
        const headers = { Authorization: `Bearer ${token}` };
        const formData = new FormData();

        formData.append('partner_logo', files[0]);

        try {
            const url = `${apiUrl}api/partner/partners/${partnerId}/`;
            
            const response = await axios.patch(url, 
                formData, 
                { headers }
            );
            setPartnerLogo(response.data.partner_logo);
        } catch (e:any) {
            console.error(e);
        }
    };

    const partnershipCss = partnershipLevel.toLowerCase();

    const getPartnerAdmins = async () => {
        axios.get(apiUrl + `api/partner/partneradmins`, 
        {
            headers: { Authorization: `Bearer ${token}`
        }
        }).then((response: any) => {
            response.data.results.forEach((item: any) => {
                setPartnershipLevel(item.partner_level);
                setUserName(item.user_first_name);
                setPartnerId(item.partner);
                
                //get partner
                axios.get(apiUrl + `api/partner/partners/${item.partner}`, 
                {
                    headers: { Authorization: `Bearer ${token}`
                }
                }).then((responsePartner: any) => {
                    setPartnerName(responsePartner.data.name);
                    setPartnerLogo(responsePartner.data.partner_logo);
                    setPartnerDescription(responsePartner.data.description);
                }).catch((err: any) => console.log(err))
            });
        }).catch((err: any) => console.log(err))
    }

    const saveButtonClicked = async () => {
        //save partner description
        try {
            const headers = { Authorization: `Bearer ${token}` };
            const formData = new FormData();

            formData.append('description', partnerDescription);

            const url = `${apiUrl}api/partner/partners/${partnerId}/`;
            
            await axios.patch(url, 
                formData, 
                { headers }
            );
            
            let i = 1;
            cards.forEach(item => {
                const formData = new FormData();

                const urlRegionMetrics = `${apiUrl}api/partner/partneradminsregionmetrics/${item.id}/`;
                formData.append('order', i.toString());
                formData.append('type', item.metricType);

                axios.patch(urlRegionMetrics, 
                    formData, 
                    { headers }
                );
                i++;
            })

        } catch (e:any) {
            console.error(e);
        }
    }

    const partnerlogoExists = partnerLogo !== '' && partnerLogo !== null;

    const callbackFunction = (childData: Item[]) => {
        setCards(childData);
    }

    return (
        <div className='premium-partner'>
            <div className='content'>
                <div className='header'>
                    <div className="digitalboost-logo">
                        <img alt='logo'  src='/logo.png' height={'24px'} />
                    </div>
                    <div className={`partner-logo ${partnerlogoExists ? "partner-logo-exists": ""}`}>
                        {
                            partnerlogoExists && 
                            <img alt='premium partner logo' src={partnerLogo} height={'24px'} />
                        }
                    </div>
                    
                    <div className='header-navigation'>
                        <PartnerNavigationMenu userName={userName.toString()} />
                    </div>
                </div>
            </div>

            <div className={`partner-level ${partnershipCss}`}>
                <div className='partner-level-content'>
                    {partnershipLevel} Partner
                </div>
            </div>

            <div className='content-body'>
                <h1>{partnerName} Preferences</h1>

                <Button
                    onClickFunction={saveButtonClicked}
                    type={'primary'}
                    text={'Save'}
                />
                
                <h2>Configure Insights Dashboard</h2>
                <div className='divider' />

                {/* @ts-ignore:next-line */}
                <DndProvider backend={HTML5Backend}>
					<Metrics parentCallback={callbackFunction} />
				</DndProvider>

                <h2>Profile</h2>
                <div className='divider' />
                <div className="partner-logo-wrapper">
                    <img className="partner-logo" alt='premium partner logo' src={partnerLogo} height={'24px'} />

                    <ImageUploader
                        withIcon={false}
                        withPreview={false}
                        label=""
                        singleImage={true}
                        buttonText={partnerLogo ? "Change Logo" : "Upload Logo"}
                        onChange={(e) => onDrop(e, partnerId)}
                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg', '.peg']}
                        maxFileSize={2248576}
                        fileSizeError="File size is too big, please keep it below 2 MB"
                    />
                </div>
                    
                <div className='divider' />

                <TextAreaWithLabel
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPartnerDescription(e.target.value);
                    }}
                    label={'Description'}
                    rows={10}
                    placeHolder={'Enter company description'}
                    defaultValue={partnerDescription}
                />

                <Button
                    onClickFunction={saveButtonClicked}
                    type={'primary'}
                    text={'Save'}
                />
            </div>


        </div>
    );
}

export default PremiumPartnerPreferences;
