import React from 'react';
import './input-with-label.scss';

interface Props extends React.HTMLProps<HTMLInputElement> {
    label?: string | undefined;
    placeHolder?: string | undefined;
    onInput?: any;
    type: string;
    defaultValue?: any;
    readOnly?: boolean;
    mandatory?: boolean;
    maxlength?: number;
}

const InputWithLabel = ({ type, label, placeHolder, defaultValue, maxlength, mandatory, onInput = () => { console.log('onInput') }, ...props }: Props) => {

    return (
        <div className='input-with-label'>
            {label &&
                <label>{label} {mandatory && <span className='input-with-label__mandatory-asterisk'>{'\u002A '}</span>}</label>
            }

            <input onInput={onInput} placeholder={placeHolder} type={type} defaultValue={defaultValue} maxLength={maxlength} required={mandatory} {...props} />
        </div>

    )
}

export default InputWithLabel;
