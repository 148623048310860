import React, { useCallback } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io'
import { useHistory } from 'react-router-dom';

import Cookie from 'js-cookie';
import './nav.scss';

import {
  OrgProfile
} from '../../types';
interface Props {
  orgProfile?: false | OrgProfile;
}

const Nav = ({ orgProfile = false }: Props) => {

  const history = useHistory();

  const handleLogout = useCallback(() => {
    Cookie.remove('accessOrgTokenDB');
    Cookie.remove('refreshOrgTokenDB');
    Cookie.remove('draftBoostCallUUID');
    
    window.location.href = 'https://www.digitalboost.org.uk';
  }, []);
  
  const goDashboard = () => {
    history.push('/');
  }
  const goPreferences = () => {
    history.push('/preferences');
  }



  return (
    <nav className="nav">
      <div className="nav-container">
        {/* <Link to="/"><img className="logo" src="/nav-logo.svg" alt="digital boost" /></Link> */}
        <a href="https://www.digitalboost.org.uk"><img className="logo" src="/nav-logo.svg" alt="digital boost" /></a>

        {orgProfile &&
          <div className="nav-link-container">

          <span onClick={() => { history.push('/') }} className='quick-link'>
            Dashboard
            </span> 
            <span onClick={() => { history.push('/mentor-select') }} className='quick-link'>
              Mentoring
            </span>

            <span className="nav-link" style={{ display: 'none' }}>

            </span>
          <span className="nav-link-separator" />
            <span className="nav-link nav__burger-menu-container">

              <span>
                <span className='profile-pic'>
                {orgProfile.profile_picture &&
                  <img height={'31px'} width={'31x'} alt='avatar' src={orgProfile.profile_picture} />

                  }
                {!orgProfile.profile_picture &&
                  <img height={'31px'} alt='avatar' src={'/avatar.png'} />

                  }
                </span>
              <span className='name'>{orgProfile.first_name}</span>
              </span>
              <IoMdArrowDropdown color="#333333" />
              <div>
                <div className='menu-box'>

                  <div onClick={goDashboard} >Dashboard</div>
                  <div onClick={goPreferences} >Account</div>
                  <div onClick={handleLogout}>Logout</div>

                </div>
              </div>

            </span>
          </div>
        }

      </div>
    </nav>
  );
};

export default Nav;
