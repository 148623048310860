import { createContext, useContext } from 'react';
import { OrgProfile, UserLocation } from '../types';

export type UserContextType = {
    userLocation: UserLocation | false | undefined;
    orgProfile: OrgProfile | false | undefined;
    setOrgProfile: (orgProfile: OrgProfile | false) => void;
    setUserLocationContext: (userLocation: UserLocation) => void;
    hasFinishedFetchingProfileAndLocation: boolean;
}

export const UserContext = createContext<UserContextType>({
    orgProfile: false,
    userLocation: false,
    setOrgProfile: userProfile => { },
    setUserLocationContext: userLocation => { },
    hasFinishedFetchingProfileAndLocation: false
});
export const useUserProfile = () => useContext(UserContext);