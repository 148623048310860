import React, { useState, useEffect } from 'react';
import InputWithLabel from '../InputWithLabel';
import Button from '../Button';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState<any>(false);
  const history = useHistory();

  const resetPasswordWithCode = () => {
    axios
      .post(
        apiUrl + `api/core/resetpassword/`,
        {
          code: code,
          password: password
        },
        {}
      )
      .then((response: any) => {
        setErrorMessage('Password has been reset');
        setTimeout(()=> {
         history.push('/login')
        },2000)
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  const sendPasswordResetEmail = () => {
    axios
      .post(apiUrl + `api/core/forgotpassword/`, { username: email }, {})
      .then((response: any) => {
        setErrorMessage('Please check your email for further instructions');
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  useEffect(() => {
    setCode(qs.parse(window.location.search.slice(window.location.search.lastIndexOf('?') + 1)).code);
  }, [code]);

  if (code) {
    return (
      <div className="login-page">
        <div className="login-page">
          <h1>Reset Password</h1>

          <h3>Enter a new password</h3>

          {errorMessage && <div className="error">{errorMessage}</div>}

          <div className="container">
            <div className="inputs">
              <InputWithLabel
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
                label={'Password'}
                type={'password'}
                placeHolder={'Enter your new password'}
              />
              <Button
                onClickFunction={() => resetPasswordWithCode()}
                disabled={!!!password}
                type={'primary'}
                text={'Reset Password'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="login-page">
        <div className="login-page">
          <h1>Reset Password</h1>

          {errorMessage && <div className="error">{errorMessage}</div>}

          <div className="container">
            <div className="inputs">
              <InputWithLabel
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
                label={'Email'}
                type={'text'}
                placeHolder={'Enter your email address'}
              />
              <Button
                onClickFunction={() => sendPasswordResetEmail()}
                disabled={!!!email}
                type={'primary'}
                text={'Reset Password'}
              />
            </div>
          </div>
          <div className="signup-text">
            {' '}
            You don’t have a Digital Boost account yet? <a href="/signup">Signup here </a>
          </div>
        </div>
      </div>
    );
  }
};

export default ResetPassword;
