import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState, useCallback } from 'react';
import PartnerLoginPage from './pages/LoginPage/Patner';
import ResetPassword from './components/ResetPassword';
import PremiumPartner from './pages/PremiumPartner';
import PremiumPartnerPreferences from './pages/PremiumPartner/Preferences';
import ScrollToTop from './components/ScrollToTop';
import Nav from './components/Nav';
import Cookie from 'js-cookie';
import axios from 'axios';
import { OrgProfile, UserLocation } from './types';
import {
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom';
import Footer from './components/Footer';
import './App.scss';
import { UserContext } from '../src/context/UserProfile';

const apiUrl = process.env.REACT_APP_API_URL;
const navExclusionArray = ['/premium-partner', '/partner-login', '/premium-partner-preferences'];

function App() {

  const [orgProfile, setOrgProfile] = useState<OrgProfile | false | undefined>();
  const [userLocation, setUserLocationContext] = useState<UserLocation | false | undefined>();
  const [profileFetchFinished, setProfileFetchFinished] = useState(false)
  const [locationFetchFinished, setLocationFetchFinished] = useState(false)



  const getOrgProfile = useCallback(async () => {
    if (Cookie.get('accessOrgTokenDB')) {
      const headers = { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` };

      try {
        const response = await axios.get<OrgProfile>(`${apiUrl}api/organisation/organisations/me/`, { headers });
        setOrgProfile(response.data);
        setProfileFetchFinished(true)

      } catch (e) {
        setProfileFetchFinished(true)
        console.error(e);
      }
    }
  }, [])

  const getUserLocation = useCallback(async () => {

    if (Cookie.get('accessOrgTokenDB')) {
      const headers = { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` };

      try {
        const response = await axios.get<UserLocation>(`${apiUrl}api/location/locations/me/`, { headers })
        setUserLocationContext(response.data);
        setLocationFetchFinished(true)
      } catch (e) {
        setLocationFetchFinished(true)
        console.error(e);
      }
    }
  }, [])

  useEffect(() => {
    getOrgProfile();
    getUserLocation();
  }, [getOrgProfile, getUserLocation]);

  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <Router>
        <UserContext.Provider value={{ orgProfile, userLocation, setOrgProfile, setUserLocationContext, hasFinishedFetchingProfileAndLocation: profileFetchFinished && locationFetchFinished }}>

          {navExclusionArray.indexOf(window.location.pathname) < 0 && <Nav orgProfile={orgProfile} />}

          <ScrollToTop>
            <div>
              <Switch>
                <Route path="/login">
                  <PartnerLoginPage />
                </Route>
                <Route path="/resetpassword">
                  <ResetPassword />
                </Route>
                <Route path="/premium-partner">
                  <PremiumPartner />
                </Route>
                <Route path="/premium-partner-preferences">
                  <PremiumPartnerPreferences />
                </Route>
                <Route path="/">
                  <PartnerLoginPage />
                </Route>
              </Switch>
            </div>
          </ScrollToTop>
        </UserContext.Provider>
      </Router>

      <Footer />
    </div>

  );
}

export default App;
