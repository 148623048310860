import React from 'react';
import './button.scss';

interface Props {
    text:             string;
    type:             string;
    onClickFunction?: any;
    disabled?:        boolean;
    className?:       string;
    children?: React.ReactNode
}

const Button = ({ text, type, onClickFunction, disabled, className, children }: Props) => {
    const state = disabled ? 'disabled' : '';
    const addtionalClassName = className ? className : ''; 

    return (
        <button onClick={onClickFunction} className={`button button-${type} ${state} ${addtionalClassName}`}>
            {text}
            {children}
        </button>
    );
};

export default Button;
