import React, { useEffect, useState }   from 'react';
import { ifPartnerLoggedIn }            from '../../utils/auth';
import { WindowPortal }                 from './window-portal';
import TableauEmbeded                   from './tableau-embeded';
import axios                            from 'axios';
import Cookie                           from 'js-cookie';
import LockedMetric                     from './locked-component';
import { List }                         from 'immutable';
import PartnerNavigationMenu            from './NavigationMenu/navigation-menu';
// import DownloadCSVButton                from './download-csv-button';
import './premium-partner.scss';

const apiUrl = process.env.REACT_APP_API_URL;

export interface KpiMetric {
    kpiCode: string;
    kpiValue: number;
}

const PremiumPartner = () => {
    const [businessType, setBusinessType] = useState<string>('Business');
    const [chartType, setChartType] = useState<string>('Pie chart');
    const [chartType2, setChartType2] = useState<string>('Requests');
    const [openModals, setOpenModals] = useState<string[]>([]);
    const [kpiMetrics, setKpiMetrics] = useState<List<KpiMetric>>(List());
    const [partnerName, setPartnerName] = useState<string>('');
    const [partnerLogo, setPartnerLogo] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [partnershipLevel, setpartnershipLevel] = useState<string>('');
    const [mainMetricsConfig, setMainMetricsConfig] = useState<any>([]);
    const [regionMetricsConfig, setRegionMetricsConfig] = useState<any>([]);
    
    const token = Cookie.get('accessPartnerTokenDB');

    const isModalOpen = (modalKey: string) => {
        return openModals.includes(modalKey);
    }

    const genericOpenModal = (modalKey: string) => {
        if (!openModals.includes(modalKey)){
            const newModals = [...openModals, modalKey];
            setOpenModals(newModals);
        }
    }

    const genericCloseModal = (modalKey: string) => {
        if (openModals.includes(modalKey)){
            var filteredarray = openModals.filter(function(e) { return e !== modalKey })
            const newModals = [...filteredarray];
            setOpenModals(newModals);
        }
    }

    useEffect(() => {
        (async () => {
            await ifPartnerLoggedIn();
            await getPartnerAdmins();
            getKpiMetrics();
            getMainMetricsConfig();
            getRegionMetricsConfig();
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const partnershipCss = partnershipLevel.toLowerCase();

    const getPartnerAdmins = async () => {
        axios.get(apiUrl + `api/partner/partneradmins`, 
        {
            headers: { Authorization: `Bearer ${token}`
        }
        }).then((response: any) => {
            response.data.results.forEach((item: any) => {
                setpartnershipLevel(item.partner_level);
                setUserName(item.user_first_name);
                
                //get partner
                axios.get(apiUrl + `api/partner/partners/${item.partner}`, 
                {
                    headers: { Authorization: `Bearer ${token}`
                }
                }).then((responsePartner: any) => {
                    setPartnerName(responsePartner.data.name);
                    setPartnerLogo(responsePartner.data.partner_logo);
                }).catch((err: any) => console.log(err))
            });
        }).catch((err: any) => console.log(err))
    }

    const getKpiMetrics = () => {
        axios.get(apiUrl + `api/partner/kpis?latest=true`, 
        {
            headers: { Authorization: `Bearer ${token}`
        }
        }).then((response: any) => {
            response.data.results.forEach((kpi: any) => {
                let kipMetric: KpiMetric = {
                    kpiCode: kpi.code,
                    kpiValue: kpi.value
                };
                const index = kpiMetrics.findIndex(i => i.kpiCode === kpi.code);
                if (index === -1) {
                    const newMetrics = kpiMetrics.push(kipMetric);
                    setKpiMetrics(newMetrics);
                }
            });
        }).catch((err: any) => console.log(err))
    }

    const getMainMetricsConfig = () => {
        axios.get(apiUrl + `api/partner/partneradminsmainmetrics`, 
        {
            headers: { Authorization: `Bearer ${token}`
        }
        }).then((response: any) => {
            setMainMetricsConfig(response.data.results);
        }).catch((err: any) => console.log(err))
    }

    const getRegionMetricsConfig = () => {
        axios.get(apiUrl + `api/partner/partneradminsregionmetrics`, 
        {
            headers: { Authorization: `Bearer ${token}`
        }
        }).then((response: any) => {
            setRegionMetricsConfig(response.data.results);
        }).catch((err: any) => console.log(err))
    }

    const createMetricBox = (
        value: string, 
        descriptionPar1: string, 
        descriptionPar2: string,
        modalKey: string,
        tableauUrl: string,
        hide: boolean
    ) => {
        return (
            <div key={modalKey}>
                <div className={`metric-box ${tableauUrl !== "" ? "clickable" : "" }`} 
                    onClick={() => genericOpenModal(modalKey)} >
                    <div className={`${hide ? "metric-value-hidden": "metric-value"}`}>
                        {value}
                    </div>
                    {descriptionPar1}<br/>{descriptionPar2}
                    {tableauUrl !== "" && isModalOpen(modalKey) && 
                        // @ts-ignore
                        <WindowPortal 
                            onExternalWindowClose={() => genericCloseModal(modalKey)}
                            title={`${descriptionPar1} ${descriptionPar2}`}>
                            <div className="tableau-modal-header">{descriptionPar1} {descriptionPar2}</div>
                            <TableauEmbeded 
                                url={tableauUrl}
                                height={"400px"}
                            />
                        </WindowPortal>
                    }
                </div>
            </div>
        );
    }

    const createTableauRegionPopup = (
        title: string,
        subTitle: string,
        uniqueModalKey: string,
        value: string,
        tableauUrl: string,
        height: string
    ) => {
        return (
            <div key={uniqueModalKey} className='tableau-metric-region clickable' onClick={() => genericOpenModal(uniqueModalKey)}>
                <div className='description-box'>
                    <div className='inner-description'>
                        <div className='metric-label'>
                            {title}
                        </div>
                        <div className='metric-content'>
                            {subTitle}
                            {isModalOpen(uniqueModalKey) && 
                                // @ts-ignore
                                <WindowPortal 
                                    onExternalWindowClose={() => genericCloseModal(uniqueModalKey)}
                                    title={title}>
                                    <div className="tableau-modal-header">{title}</div>
                                    <TableauEmbeded 
                                        url={tableauUrl}
                                        height={height} 
                                    />
                                </WindowPortal>
                            }
                        </div>
                    </div>
                </div>
                <div className='value'>
                    {value}
                </div>
            </div>
        );
    }

    const createChartButton = (text: string) => {
        return (
            <button 
                onClick={() => handleChartTypeButton(text)} 
                className={`button ${chartType === text ? 'selected': '' }`} >
                {text}
            </button >
        );
    }

    const createChartButton2 = (text: string) => {
        return (
            <button 
                onClick={() => handleChartTypeButton2(text)} 
                className={`button ${chartType2 === text ? 'selected': '' }`} >
                {text}
            </button >
        );
    }

    const createTableauRegion = (
        metricLabel: string,
        metricValue: string,
        tableauUrl: string,
        tableauEmbededHeight: string,
        modalKey: string
    ) => {
        return (
            <div className='tableau-region clickable' onClick={() => genericOpenModal(modalKey)}>
                <div className='metric-header'>
                    <div className='metric-label'>{metricLabel}</div>
                    <div className='metric-value'>{metricValue}</div>
                </div>
                <div className='metric-content'>
                    <TableauEmbeded 
                        url={tableauUrl}
                        height={tableauEmbededHeight}
                    />

                    {isModalOpen(modalKey) && 
                        // @ts-ignore
                        <WindowPortal 
                            onExternalWindowClose={() => genericCloseModal(modalKey)}
                            title={metricLabel} >
                            <div className="tableau-modal-header">{metricLabel}</div>
                            <TableauEmbeded 
                                url={tableauUrl}
                                height={tableauEmbededHeight} 
                            />
                        </WindowPortal>
                    }
                </div>
            </div>
        );
    }

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBusinessType(e.target.value);
    }

    const handleChartTypeButton = (text: string) => {
        setChartType(text);
    }

    const handleChartTypeButton2 = (text: string) => {
        setChartType2(text);
    }

    const getTableauTop10Metrics = () => {
        let businessBar = false;
        let businessPie = false;
        let charityBar = false;
        let charityPie = false;

        if (businessType === 'Business'){
            if (chartType === 'Bar chart'){
                businessBar = true;
            }
            else if (chartType === 'Pie chart'){
                businessPie = true;
            }
        }
        else if(businessType === 'Charity'){
            if (chartType === 'Bar chart'){
                charityBar = true;
            }
            else if (chartType === 'Pie chart'){
                charityPie = true;
            }
        }

        return (
            <>
                <div className={businessBar ? "" : "hide-metric"}>
                    <TableauEmbeded 
                        url="https://dub01.online.tableau.com/t/digitalboostuk/views/Googlestats/Top10businessneeds?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                        height={"400px"} 
                    />
                </div>
                <div className={businessPie ? "" : "hide-metric"}>
                    <TableauEmbeded 
                        url="https://dub01.online.tableau.com/t/digitalboostuk/views/Googlestats/Topbusinessneeds_1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                        height={"400px"} 
                    />
                </div>
                <div className={charityBar ? "" : "hide-metric"}>
                    <TableauEmbeded 
                        url="https://dub01.online.tableau.com/t/digitalboostuk/views/Googlestats/Top10charityneeds?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                        height={"400px"} 
                    />
                </div>
                <div className={charityPie ? "" : "hide-metric"}>
                    <TableauEmbeded 
                        url="https://dub01.online.tableau.com/t/digitalboostuk/views/Googlestats/Topcharityneeds_1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                        height={"400px"}
                    />
                </div>
            </>
        );
    }

    const getTableauMentoringDays = () => {
        let requestsChart = false;
        let occurrencesChart = false;

        if (chartType2 === 'Requests'){
            requestsChart = true;
        }
        else if (chartType2 === 'Occurrences'){
            occurrencesChart = true;
        }

        return (
            <>
                <div className={requestsChart ? "" : "hide-metric"}>
                    <TableauEmbeded 
                        url="https://dub01.online.tableau.com/t/digitalboostuk/views/MentoringScheduledM/MentoringWeekdaycreated_1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                        height={"400px"} 
                    />
                </div>
                <div className={occurrencesChart ? "" : "hide-metric"}>
                    <TableauEmbeded 
                        url="https://dub01.online.tableau.com/t/digitalboostuk/views/MentoringScheduledM/MentoringScheduledbyDayofWeek?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                        height={"400px"} 
                    />
                </div>
            </>
        );
    }

    const dynamicMainMetrics = (partnerName: string) => {
        if (partnerName === null || partnerName === ""){
            return null;
        }

        let data = mainMetricsConfig;
        let immutableList:List<any> = List(data);
        let orderedData = immutableList.sortBy(i => i.order);

        let objectToRender = orderedData.map(item => {
            let kpiValue;
            if (item.kpi_metric !== "")
            {
                const kpiMetric = kpiMetrics.find(i => i.kpiCode === item.kpi_metric);
                kpiValue = kpiMetric?.kpiValue;
            }

            const valueToShow = item.text_when_hidden !== "" ? item.text_when_hidden : kpiValue;

            return createMetricBox(
                    valueToShow,
                    item.tex_line_1,
                    item.tex_line_2,
                    'main_metric_' + item.id,
                    item.tableau_link,
                    item.hide_kpi_metric_value
                );
        });
        return objectToRender;
    }

    const dynamicRegionTableaus = (partnerName: string) => {
        if (partnerName === null || partnerName === ""){
            return null;
        }

        let data = regionMetricsConfig;
        let immutableList:List<any> = List(data);
        let orderedData = immutableList.filter(x => !x.hide).sortBy(i => i.order);

        let objectToRender = orderedData.map(item => {
            let kpiValue;
            if (item.kpi_metric !== "")
            {
                const kpiMetric = kpiMetrics.find(i => i.kpiCode === item.kpi_metric);
                kpiValue = kpiMetric?.kpiValue;
            }

            const valueToShow = kpiValue !== undefined ? kpiValue.toString() : "";
            
            if (item.locked){
                return <LockedMetric label={item.region_title} content={item.region_subtitle} />;
            }

            if (item.type === "divider"){
                return <div className='divider' />;
            }

            if (item.type === "in-page-metric"){
                return createTableauRegion(
                    item.region_title, 
                    valueToShow,
                    item.tableau_link,
                    item.height,
                    'region-metric' + item.id
                );
            }

            if (item.type === "popup-metric"){
                return createTableauRegionPopup(
                    item.region_title,
                    item.region_subtitle,
                    'region-metric' + item.id,
                    valueToShow,
                    item.tableau_link,
                    item.height
                );
            }

            if (item.type === "hard-coded-top-10-skills")
            {
                return (
                    <div key={'top-10-skills-requested'} className='top-10-skills-requested'>
                        <div className='metric-header'>
                            <div className='metric-label'>Top 10 skills requested</div>
                            <div className='controls'>
                                <input type='radio' 
                                    id='radioBusiness' 
                                    value='Business'
                                    checked={businessType === 'Business'}
                                    onChange={handleRadioChange}
                                    />
                                <label htmlFor='radioBusiness'>Business</label>
                                <input type='radio' 
                                    id='radioCharity' 
                                    value='Charity'
                                    checked={businessType === 'Charity'} 
                                    onChange={handleRadioChange}
                                    />
                                <label htmlFor='radioCharity'>Charity</label>
                                <input type='radio' 
                                    id='radioBoth' 
                                    value='Both'
                                    checked={businessType === 'Both'} 
                                    onChange={handleRadioChange}
                                    disabled={true}/>
                                <label htmlFor='radioBoth'>Both</label>
                            </div>
                        </div>
                        <div className='chart-options'>
                            {createChartButton('Bar chart')}
                            {createChartButton('Pie chart')}
                        </div>
                        <div>
                            {getTableauTop10Metrics()}
                        </div>
                    </div>
                );
            }

            if (item.type === "hard-coded-hours-contributed"){
                return (
                    <div className='hours-contributed-box clickable' onClick={() => genericOpenModal('hours-contributed')}>
                        <div className='description-box'>
                            <div className='inner-description'>
                                <div className='metric-label'>
                                    Hours contributed / Hours pledged
                                </div>
                                <div className='metric-content'>
                                    Number of hours volunteers pledged for this week and actual hours contributed
                                    {isModalOpen('hours-contributed') && 
                                        // @ts-ignore
                                        <WindowPortal 
                                            onExternalWindowClose={() => genericCloseModal('hours-contributed')}
                                            title="Hours contributed / Hours pledged">
                                            <div className="tableau-modal-header">Hours contributed / Hours pledged</div>
                                            <TableauEmbeded 
                                                url={"https://dub01.online.tableau.com/t/digitalboostuk/views/Googlestats/CumulativeofindividualsmentoredbyGoogle_1?:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n"}
                                                height={"400px"} 
                                            />
                                            <TableauEmbeded 
                                                url={"https://dub01.online.tableau.com/t/digitalboostuk/views/Googlestats/Sheet11?:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n"}
                                                height={"400px"} 
                                            />
                                        </WindowPortal>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='value'>
                            {/* TODO / {hoursPledgedByPartnerVolunteers} */}
                        </div>
                    </div>
                );
            }

            if (item.type === "hard-coded-requests-occurrences"){
                return (
                    <div className='mentoring-days-requests-occurrence'>
                        <div className='metric-header'>
                            <div className='metric-label'>Mentoring Days | Requests vs. Occurrences</div>
                        </div>
                        <div className='chart-options'>
                            {createChartButton2('Requests')}
                            {createChartButton2('Occurrences')}
                        </div>
                        <div>
                            {getTableauMentoringDays()}
                        </div>
                    </div>
                );
            }

            return null;
        });
        return objectToRender;
    }

    const partnerlogoExists = partnerLogo !== '' && partnerLogo !== null;

    return (
        <div className='premium-partner'>
            <div className='content'>
                <div className='header'>
                    <div className="digitalboost-logo">
                        <img alt='logo'  src='/logo.png' height={'24px'} />
                    </div>
                    <div className={`partner-logo ${partnerlogoExists ? "partner-logo-exists": ""}`}>
                        {
                            partnerlogoExists && 
                            <img alt='premium partner logo' src={partnerLogo} height={'24px'} />
                        }
                    </div>
                    
                    <div className='header-navigation'>
                        <PartnerNavigationMenu userName={userName.toString()} />
                    </div>
                </div>
            </div>

            <div className={`partner-level ${partnershipCss}`}>
                <div className='partner-level-content'>
                    {partnershipLevel} Partner
                </div>
            </div>

            <div className='content-body'>
                {/* <DownloadCSVButton/> */}
                <h1>{partnerName} Insights</h1>
                {/* TODO: SHERRY wanted to change the wording here. */}
                {/* <p className="sub-header">Here’s how your organisation performed on Digital Boost</p> */}
                <p>Click on the category to view details</p>
                
                <div className='main-metrics'>
                    {dynamicMainMetrics(partnerName.toLowerCase())}
                </div>

                {dynamicRegionTableaus(partnerName.toLowerCase())}
            </div>
        </div>
    );
}

export default PremiumPartner;
