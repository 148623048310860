import React from 'react';
import ReactDOM from 'react-dom';

function copyStyles(sourceDoc: Document, targetDoc: Document) {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (styleSheet instanceof CSSStyleSheet)
    {
      if (styleSheet.cssRules) { // for <style> elements
        const newStyleEl = sourceDoc.createElement('style');
  
        Array.from(styleSheet.cssRules).forEach(cssRule => {
          // write the text of each rule into the body of the style element
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });
  
        targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
        const newLinkEl = sourceDoc.createElement('link');
  
        newLinkEl.rel = 'stylesheet';
        newLinkEl.href = styleSheet.href;
        targetDoc.head.appendChild(newLinkEl);
      }
    }
  });
}

interface Props {
  onExternalWindowClose: any;
  title: any;
}

export class WindowPortal extends React.Component<Props> {
  containerElement: HTMLElement = document.createElement("div");
  externalWindow: Window|null = null;
  
  componentDidMount() {
    this.externalWindow = window.open('', '_blank', 'width=600,height=400,left=200,top=200');
    this.containerElement.className = "window-portal";

    if (this.externalWindow){
      copyStyles(document, this.externalWindow.document);
      this.externalWindow.document.body.appendChild(this.containerElement);
      this.externalWindow.document.title = this.props.title;

      this.externalWindow.addEventListener('beforeunload', () => {
        this.props.onExternalWindowClose();
      });
    }
  }

  render() {
    // @ts-ignore
    return ReactDOM.createPortal(this.props.children, this.containerElement);
  }
}
