import React from 'react';
import './footer.scss';

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="container-8 w-container">
                <div className="column-footer-main w-row">
                    <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
                        <div className="div-block-6">
                            <img src="https://uploads-ssl.webflow.com/5e8ddd9cd35d92e38fa576e2/5ece71feed7fb52c2a118d4f_Digital_Boost_Primary_Logo_RGB%20WHITE.svg" width="170" alt="" className="image-26" />
                            <div className="text-block-12">
                                Copyright 2020 - All rights reserved
                            </div>
                        </div>
                    </div>
                    <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
                        <div className="div-block-6">
                            <a data-gatrack="footer-volunteer-learnmore,clicked" href="https://www.digitalboost.org.uk/volunteers" className="footer-link-1">For Volunteers</a>
                            <a data-gatrack="footer-aboutus,clicked" href="https://www.digitalboost.org.uk/about-us/about-us" className="footer-link-1">About Us</a>
                            <a data-gatrack="footer-signuppage,clicked" href="https://www.digitalboost.org.uk/sign-up-for-digital-boost" className="footer-link-1">Sign Up</a>
                            <a data-gatrack="footer-partners,clicked" href="https://www.digitalboost.org.uk/partners" className="footer-link-1">Our Partners</a>
                            <a data-gatrack="footer-partners,clicked" href="https://www.digitalboost.org.uk/faq" className="footer-link-1">Frequently Asked Questions</a>
                        </div>
                    </div>
                    <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
                        <div className="div-block-6">
                            <a data-gatrack="footer-faqs,clicked" href="https://www.digitalboost.org.uk/press" className="footer-link-1">Press</a>
                            <a data-gatrack="footer-contactus,clicked" href="https://www.digitalboost.org.uk/contact" className="footer-link-1">Contact Us</a>
                            <a data-gatrack="footer-privacypolicy,clicked" href="https://www.digitalboost.org.uk/legals/privacy-policy" className="footer-link-1">Privacy Policy</a>
                            <a data-gatrack="footer-termsofuse,clicked" href="https://www.digitalboost.org.uk/legals/terms-and-conditions" className="footer-link-1">Terms of use</a>
                        </div>
                    </div>
                    <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
                        <div className="div-block-6">
                            <a data-gatrack="footer-linkedin,clicked" href="https://www.linkedin.com/company/digitalboost-org" target="_blank" rel="noopener noreferrer" className="footer-link-1 nolink">LinkedIn</a>
                            <a data-gatrack="footer-instagram,clicked" href="https://www.instagram.com/digitalboost.org.uk" target="_blank" rel="noopener noreferrer" className="footer-link-1 nolink">Instagram</a>
                            <a data-gatrack="footer-facebook,clicked" href="https://www.facebook.com/digitalboost.org.uk" target="_blank" rel="noopener noreferrer" className="footer-link-1 nolink">Facebook</a>
                            <a data-gatrack="footer-twitter,clicked" href="https://twitter.com/digitalboost_uk" target="_blank" rel="noopener noreferrer" className="footer-link-1 nolink">Twitter</a>
                        </div>
                    </div>
                </div>
                <div className="charity">
                    <p className="ko">Digital Boost is owned and operated by Founders4Schools (registered charity number 1162197)</p>
                </div>
                <div className="div-block-27">
                </div>
            </div>
        </footer>
    )
}

export default Footer;
