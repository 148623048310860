import axios from 'axios';
import Cookie from 'js-cookie';
import qs from 'qs';

const apiUrl = process.env.REACT_APP_API_URL;

const loginUsingRefreshToken = async (refreshToken: any) => {
    return axios
        .post(
            apiUrl + `api/token/refresh/`,
            {
                refresh: refreshToken,

            },
            {
                headers: {},
            }
        )
        .then((response: any) => {
            // set cookie that expires in a day

            Cookie.set('accessOrgTokenDB', response.data.access, {
                expires: 1,
            });

            const attemptedDomain = window.location.pathname + window.location.search;
            window.location.href = (attemptedDomain);
            return response;
        })
        .catch((err: any) => {
            console.log(err.response)
        });
};

const loginWithOTLC = async (otlc: any) => {

    return axios
        .post(
            apiUrl + `api/token/otlc/`,
            {
                otlc: otlc,
                "user_type": "organisation"

            },
            {
                headers: {},
            }
        )
        .then((response: any) => {
            // set cookie that expires in a day

            Cookie.set('accessOrgTokenDB', response.data.access, {
                expires: 1,
            });

            const attemptedDomain = window.location.pathname + window.location.search;
            window.location.href = (attemptedDomain);

            return response;

        })
        .catch((err: any) => {
            return (err.response)
        });

}

export async function ifLoggedIn() {


    /* check if they are logged in first by checking if have an access token,
    then by checking they have a refresh token and trying to receive a n access token from it

    then check if they have an otlc and try loging in with that
    
    if they dont have any then redirect them to the login page but hold the link they were
    trying to get to in the query string
    
    */

    let otlc = qs.parse(window.location.search.slice(window.location.search.lastIndexOf('?') + 1)).otlc;

    const attemptedDomain = window.location.pathname + window.location.search;


    if (!Cookie.get('accessOrgTokenDB')) {
        if (!Cookie.get('refreshOrgTokenDB')) {
            if (!otlc) {
                window.location.href = ('/login?redirect=' + attemptedDomain)
            } else {
                const response = await loginWithOTLC(otlc);

                if (response.status === 401) {
                    window.location.href = ('/login?redirect=' + attemptedDomain)
                }
            }
        } else {
            const response = await loginUsingRefreshToken(Cookie.get('refreshOrgTokenDB'));
            if (response.status === 401) {
                window.location.href = ('/login?redirect=' + attemptedDomain)

            }
        }
    }
}

//todo what is otlc?
export async function ifPartnerLoggedIn() {


    /* check if they are logged in first by checking if have an access token,
    then by checking they have a refresh token and trying to receive a n access token from it

    then check if they have an otlc and try loging in with that
    
    if they dont have any then redirect them to the login page but hold the link they were
    trying to get to in the query string
    
    */

    const attemptedDomain = window.location.pathname + window.location.search;


    if (!Cookie.get('accessPartnerTokenDB')) {
        if (!Cookie.get('refreshPartnerTokenDB')) {
            window.location.href = ('/partner-login?redirect=' + attemptedDomain)
        } else {
            const response = await partnerLoginUsingRefreshToken(Cookie.get('refreshPartnerTokenDB'));
            if (response.status === 401) {
                window.location.href = ('/partner-login?redirect=' + attemptedDomain)

            }
        }
    }
}

export const logoutPartner = () => {
    /** 
    To logout remove cookies and redirect to login page
    */
   
    if (Cookie.get('accessPartnerTokenDB')){
        Cookie.remove('accessPartnerTokenDB');
    }

    if (Cookie.get('refreshPartnerTokenDB')){
        Cookie.remove('refreshPartnerTokenDB');
    }

    window.location.href = ('/partner-login')
}

const partnerLoginUsingRefreshToken = async (refreshToken: any) => {
    return axios
        .post(
            apiUrl + `api/token/refresh/`,
            {
                refresh: refreshToken,
            },
            {
                headers: {},
            }
        )
        .then((response: any) => {
            // set cookie that expires in a day

            Cookie.set('accessPartnerTokenDB', response.data.access, {
                expires: 1,
            });

            const attemptedDomain = window.location.pathname + window.location.search;
            window.location.href = (attemptedDomain);
            return response;
        })
        .catch((err: any) => {
            console.log(err.response)
        });
};
